import { navigate } from "gatsby";
import React from "react";
import ThumbsUp2 from "./thumbs-up-2.svg";

const Cta2 = () => {
  return (
    <div className="border-2 border-amber-700 rounded-xl px-6 py-4 mb-6 grid grid-cols-1 gap-3 md:grid-cols-2">
      <div className="text-center md:text-left">
        <div className="text-neutral-700">
          <img
            className="inline-block mr-2 -mt-2"
            src={ThumbsUp2}
            alt="Thumbs up icon"
          />
          Trusted by more than <b>9,700 students.</b>
        </div>
        <p className="text-xl font-bold mt-1 text-neutral-700">
          Are you ready to start your journey?
        </p>
      </div>
      <div className="grid gap-4 md:gap-6 grid-cols-2">
        <button
          className="bg-blue-700 text-sm text-white rounded-2xl
    focus:bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700
    active:bg-blue-800 px-2 py-3 w-full"
          onClick={() => {
            navigate("/sign-up");
          }}
        >
          SIGN UP NOW
        </button>
        <button
          className="bg-blue-700 text-sm text-white rounded-2xl
    focus:bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700
    active:bg-blue-800 px-2 py-3 w-full"
          onClick={() => {
            navigate("/sign-up");
          }}
        >
          SAVE ON A YEAR
        </button>
      </div>
    </div>
  );
};

export default Cta2;
