import { apiSlice, getMutationHeaders } from "../../app/apiSlice";
import { CmPayment } from "../../types";

export interface PricesResponse {
  success: boolean;
  response: {
    customer_country: string;
    products: {
      currency: string;
      product_id: number | string;
      product_title: string;
      price: {
        gross: number;
        net: number;
        tax: number;
      };
      subscription: {
        price: {
          gross: number;
          net: number;
          tax: number;
        };
      };
    }[];
  };
}

// An array of Paddle product ID's.
const productIds = [
  process.env.GATSBY_PRODUCT_ID_MONTHLY,
  process.env.GATSBY_PRODUCT_ID_ANNUAL,
];

export const checkoutApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    prices: builder.query<PricesResponse, void>({
      query: () => ({
        url: `prices?product_ids=${productIds.join()}`,
      }),
    }),
    checkoutSubmitEmail: builder.mutation<
      { emailExists: boolean },
      { email: string }
    >({
      query: ({ email }) => ({
        url: `checkout-submit-email?_format=json`,
        method: "POST",
        body: {
          email: email,
        },
        headers: getMutationHeaders({ type: "json" }),
      }),
    }),
  }),
});

export const { usePricesQuery, useCheckoutSubmitEmailMutation } =
  checkoutApiSlice;
