import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Pricing from "../features/frontpage/Pricing";
import Navbar from "../features/navbar/Navbar";
import Swoosh from "../features/frontpage/swoosh.svg";
import SectionTitle from "../components/SectionTitle";
import Textbooks from "../features/frontpage/Textbooks";
import Footer from "../features/footer/Footer";
import Reviews from "../features/frontpage/Reviews";
import Features from "../features/frontpage/Features";
import Faq from "../features/frontpage/Faq";
import About from "../features/frontpage/About";
import Cta2 from "../features/frontpage/Cta2";
import Contact from "../features/frontpage/Contact";
import Lightbulb from "../features/frontpage/lightbulb.svg";
import { HeadProps } from "gatsby";
import Seo from "../features/seo/Seo";
import Prompt from "../features/frontpage/Prompt";

const Home2 = () => {
  return (
    <>
      <Navbar />
      <Prompt />

      <div className="max-w-screen-xl mx-auto">
        <div className="px-3 pt-8 lg:pt-14">
          <div className="text-center lg:text-left">
            <h1 className="text-3xl lg:text-4xl text-neutral-700">
              Giancoli Solutions on Video
            </h1>
            <h2 className="text-neutral-700 xs:text-xl mt-2">
              More than <b className="font-bold">9,700 students helped. </b>
              You'll be next!
            </h2>
          </div>
        </div>
        <div className="relative lg:grid lg:grid-cols-2">
          <div className="mt-3 xs:mt-4 md:mt-8 lg:mt-12 px-3">
            <Pricing />
          </div>
          <div className="relative pb-8 z-10">
            <div className="flex justify-center lg:max-w-[600px] lg:absolute lg:-top-[140px]">
              <StaticImage
                src="../images/shaun-teaching-h-1000.png"
                alt="Shaun teaching"
                loading="eager"
                quality={80}
                width={800}
              />
            </div>
            <img
              className="w-full block absolute -bottom-1 lg:hidden"
              src={Swoosh}
              alt=""
            />
          </div>
        </div>
        <div className="px-3 pb-8 bg-neutral-100 lg:bg-white lg:mt-[67px] xl:mt-[67px]">
          <div id="textbooks" className="scroll-mt-16">
            <div className="lg:hidden">
              <SectionTitle
                backgroundStyle="white"
                title="Choose your textbook"
              />
            </div>
            <div className="hidden lg:block w-2/3">
              <SectionTitle
                backgroundStyle="none"
                title="Choose your textbook"
              />
            </div>
          </div>

          <div className="mt-8 lg:w-7/12 lg:z-30 relative">
            <Textbooks />
          </div>
        </div>
      </div>
      <div className="relative mt-8 lg:mt-16 xl:mt-24">
        <img
          className="hidden lg:block absolute bottom-16 w-full z-20"
          src={Swoosh}
          alt=""
        />
        <div
          id="features"
          className="max-w-screen-xl mx-auto px-3 scroll-mt-16"
        >
          <SectionTitle backgroundStyle="gray" title="Features" />
        </div>
        <img
          className="md:hidden absolute right-3 -top-3"
          src={Lightbulb}
          alt="Lightbulb"
        />
      </div>
      <div className="px-3 max-w-screen-xl mx-auto">
        <Features />
      </div>

      <div className="max-w-screen-xl bg-amber-300 text-neutral-700 font-bold text-center py-4 px-3 mt-8 xl:rounded-xl mx-auto xl:mb-8">
        Pause, rewind, repeat, and never miss what is being said.
      </div>

      <div className="bg-neutral-100 pt-6">
        <div id="reviews" className="max-w-screen-xl mx-auto px-3 scroll-mt-16">
          <SectionTitle backgroundStyle="white" title="Reviews" />
          <Reviews />
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-3 pt-6">
        <Cta2 />
        <div id="faq" className="scroll-mt-16">
          <SectionTitle
            backgroundStyle="gray"
            title="Frequently Asked Questions"
          />
          <Faq />
        </div>
      </div>
      <div className="py-6 bg-neutral-100 mt-6">
        <div id="about" className="max-w-screen-xl mx-auto px-3 scroll-mt-16">
          <SectionTitle
            backgroundStyle="white"
            title="About Giancoli Answers"
          />
          <About />
        </div>
      </div>
      <div
        id="contact"
        className="max-w-screen-xl mx-auto px-3 my-6 scroll-mt-20"
      >
        <Contact />
      </div>
      <div className="">
        <Footer />
      </div>
    </>
  );
};

export default Home2;

export const Head = (props: HeadProps) => {
  return <Seo pathname="" />;
};
