import React from "react";
import SectionTitleBackgroundGraySquareEnd from "./section-title-background--gray--square-end.svg";
import SectionTitleBackgroundGray from "./section-title-background--grey.svg";
import SectionTitleBackgroundWhite from "./section-title-background--white.svg";

interface SectionTitleProps {
  title: string;
  backgroundStyle: "white" | "gray" | "gray-square-end" | "none";
}
const SectionTitle = ({ title, backgroundStyle }: SectionTitleProps) => {
  let src = SectionTitleBackgroundGray;
  switch (backgroundStyle) {
    case "gray":
      src = SectionTitleBackgroundGray;
      break;
    case "gray-square-end":
      src = SectionTitleBackgroundGraySquareEnd;
      break;
    case "white":
      src = SectionTitleBackgroundWhite;
      break;
  }
  return (
    <div className="relative">
      {(backgroundStyle !== "none" && <img
        className={`absolute block w-full h-[15px] ${backgroundStyle === "white" ? "bottom-1" : "bottom-0.5"
          }`}
        src={src}
        alt=""
      />)}

      <div className="tracking-wider relative text-xl xs:text-2xl text-neutral-700 font-bold">
        {title.toUpperCase()}
      </div>
    </div>
  );
};

export default SectionTitle;
