import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Textbooks = () => {
  return (
    <div className="grid relative grid-cols-1 md:grid-cols-3 gap-8 z-20">
      <div>
        <Link to="/giancoli-physics-7th-edition-chapters">
          <div
            data-cy="7th-edition-image"
            className="bg-white text-center border border-neutral-100 rounded-xl p-4 shadow-lg max-w-[200px] mx-auto"
          >
            <StaticImage
              src="../../images/cover--7th-edition.jpeg"
              alt="Giancoli 7th Edition textbook cover"
              placeholder="tracedSVG"
              className=""
              loading="eager"
              width={140}
            />
          </div>
        </Link>
        <div className="text-center mt-2">
          <Link
            className="text-blue-700 hover:text-blue-800 hover:underline font-bold"
            to="/giancoli-physics-7th-edition-chapters"
          >
            7th Edition
          </Link>
        </div>
      </div>
      <div>
        <Link to="/giancoli-physics-6th-edition-chapters">
          <div className="bg-white relative text-center border border-neutral-100 rounded-xl p-4 shadow-lg max-w-[200px] mx-auto">
            <StaticImage
              src="../../images/cover--6th-edition.jpeg"
              alt="Giancoli 6th Edition textbook cover"
              placeholder="tracedSVG"
              loading="eager"
              width={140}
              className="mt-4"
            />
          </div>
        </Link>
        <div className="text-center mt-2">
          <Link
            className="text-blue-700 hover:text-blue-800 hover:underline font-bold"
            to="/giancoli-physics-6th-edition-chapters"
          >
            6th Edition
          </Link>
        </div>
      </div>
      <div>
        <Link to="/giancoli-physics-7th-global-edition-chapters">
          <div className="bg-white relative text-center border border-neutral-100 rounded-xl p-4 shadow-lg max-w-[200px] mx-auto">
            <StaticImage
              src="../../images/cover--global-edition.jpeg"
              alt="Giancoli global Edition textbook cover"
              placeholder="tracedSVG"
              className=""
              loading="eager"
              width={140}
            />
          </div>
        </Link>
        <div className="text-center mt-2">
          <Link
            className="text-blue-700 hover:text-blue-800 hover:underline font-bold"
            to="/giancoli-physics-7th-global-edition-chapters"
          >
            Global Edition
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Textbooks;
