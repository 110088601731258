import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "@reach/router";
import { parse } from "query-string";

const Prompt = () => {

  const location = useLocation();
  const queryParams = parse(location.search);
  const accountBlocked = queryParams["account_blocked"];
  const unsubscribedComment = queryParams["unsubscribed_comment"];

  useEffect(() => {
    console.log(unsubscribedComment);
    if (accountBlocked === "1") {
      toast.error('Account does not exist or is blocked.');
    }
    if (unsubscribedComment === "1") {
      toast.success("You have been unsubscribed from notifications to this comment");
    }

  }, [accountBlocked, unsubscribedComment]);

  return <></>
};

export default Prompt;
