import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const About = () => {
  return (
    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-3 lg:grid-cols-12">
      <div className="text-center lg:text-left lg:col-span-2">
        <StaticImage
          className="rounded-full"
          src="./ga-headshot-cropped.jpeg"
          alt="Shaun Dychko on Cambie Street bridge, Vancouver, BC"
        />
      </div>
      <div className="text-neutral-700 lg:col-span-10">
        <p>
          Giancoli Answers was created by Shaun Dychko, a teacher with more than
          12 years experience teaching high school physics and mathematics,
          mostly at Point Grey Secondary in Vancouver, Canada. I have taught AP
          Physics, and all levels of high school mathematics. I studied physics
          at the University of British Columbia, where I obtained both my B.Sc.,
          and B.Ed.
        </p>
        <p className="mt-6">
          I sincerely hope these videos will be helpful with your, or your
          son/daughter's studies. I put a great deal of effort into making them
          as clear as possible, and finding a balance between being concise
          while also being thorough enough to raise flags on common mistakes,
          and making a bit of time for occasional intriguing digressions.
        </p>
        <p className="mt-6">
          I am also the creator of{" "}
          <a
            className="text-blue-700 hover:text-blue-800 hover:underline"
            href="https://collegephysicsanswers.com"
          >
            College Physics Answers
          </a>{" "}
          which provides solutions to problems in the{" "}
          <a
            className="text-blue-700 hover:text-blue-800 hover:underline"
            href="https://openstax.org/details/books/college-physics-2e"
          >
            College Physics
          </a>{" "}
          textbook published by OpenStax. The textbook is available for free to
          view online or download as a PDF.
        </p>
      </div>
    </div>
  );
};

export default About;
