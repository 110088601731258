import React, { useEffect, useState } from "react";
import { getPriceLocalized } from "../../utils";
import { usePricesQuery } from "./checkoutApiSlice";

interface PriceProps {
  priceUsd: string;
  productId: number;
}

const Price = ({ priceUsd, productId }: PriceProps) => {
  const [price, setPrice] = useState(priceUsd);
  const { data: pricesData, isFetching } = usePricesQuery();

  useEffect(() => {
    if (pricesData && !isFetching) {
      const result = pricesData.response.products.find(
        (item) => item.product_id == productId
      );
      if (result) {
        setPrice(
          getPriceLocalized(
            result.currency,
            result.subscription.price.net.toString()
          )
        );
      }
    }
  }, [pricesData, isFetching]);

  return <>{price}</>;
};

export default Price;
