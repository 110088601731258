import { Link } from "gatsby";
import React from "react";
import QuickAnswer from "./quick-answer.svg";
import ThumbsUp from "./thumbs-up.svg";
import Pencil from "./pencil.svg";
import Lightbulb from "./lightbulb.svg";

const Features = () => {
  return (
    <div className="mt-6">
      <div className="md:flex gap-x-10">
        <div className="hidden md:block">
          <img className="" src={Lightbulb} alt="Lightbulb" />
        </div>
        <div className="basis-2/5">
          <div>
            <span className="text-3xl font-bold text-neutral-800">1,930</span>
            <span className="font-bold text-neutral-800"> video solutions</span>
          </div>
          <p className="text-neutral-800">
            for ALL regular problems in{" "}
            <Link
              className="text-blue-700 hover:text-blue-800 font-bold hover:underline"
              to="/giancoli-physics-7th-edition-chapters"
            >
              Giancoli's 7th Edition
            </Link>
          </p>
        </div>
        <div className="mt-4 md:mt-0 basis-2/5">
          <div>
            <span className="text-3xl font-bold text-neutral-800">1,681</span>
            <span className="font-bold text-neutral-800"> video solutions</span>
          </div>
          <p className="text-neutral-800">
            for most regular problems in{" "}
            <Link
              className="text-blue-700 hover:text-blue-800 font-bold hover:underline"
              to="#"
            >
              Giancoli's 6th Edition
            </Link>
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 gap-x-5 mt-8">
        <div className="text-neutral-700 border rounded-2xl border-amber-700 px-6 pb-4 pt-14 relative">
          <b>Final answer</b> provided in <b>text form</b> for quick reference
          above each video, and formatted nicely as an <b>equation.</b>
          <img
            className="absolute -top-6 left-1/2 -translate-x-1/2"
            src={QuickAnswer}
            alt="Quick answer example"
          />
        </div>
        <div className="text-neutral-700 border rounded-2xl border-amber-700 px-6 pb-4 pt-14 relative">
          <b>Pen colors</b> make the step-by-step solutions clear. Red is used
          to illustrate algebra steps, and to substitute numeric values in the
          final step of a solution. When a solution switches to a new train of
          thought, a different pen color emphasizes the switch, so that
          solutions are very methodical and organized.
          <img
            className="absolute -top-4 left-1/2 -translate-x-1/2"
            src={Pencil}
            alt="Pencil"
          />
        </div>
        <div className="text-neutral-700 border rounded-2xl border-amber-700 px-6 pb-4 pt-14 relative">
          Solutions are <b>classroom tested,</b> and created by an{" "}
          <b>experience physics teacher.</b> Videos are delivered with a{" "}
          <b>high performance</b> content delivery network. No waiting for
          videos to load or buffer.
          <img
            className="absolute -top-4 left-1/2 -translate-x-1/2"
            src={ThumbsUp}
            alt="Thumbs up"
          />
        </div>
      </div>
    </div>
  );
};

export default Features;
