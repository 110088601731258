import React from "react";
import Envelope from "./envelope.svg";

const Contact = () => {
  return (
    <div className="border-2 border-amber-700 rounded-xl px-6 py-6 md:py-8 mb-6 gap-2 grid grid-cols-1 md:grid-cols-2 items-center">
      <div className="text-center md:text-left">
        <div className="text-neutral-700">
          <img
            className="inline-block mr-2 -mt-2"
            src={Envelope}
            alt="Envelope"
          />
          Have a question?
        </div>
        <p className="text-xl text-neutral-700 my-1 font-bold">
          I will be happy to help!
        </p>
      </div>
      <div className="text-center md:text-left">
        <div className="md:text-right">
          <a
            className="bg-blue-700 text-sm text-white rounded-2xl
                focus:bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700
                active:bg-blue-800 px-6 py-3 inline-block"
            href="mailto:learn@giancolianswers.com"
          >
            SEND A MESSAGE
          </a>
        </div>
        <a
          className="text-blue-700 hover:text-blue-800 hover:underline font-bold block mt-3 md:text-right"
          href="mailto:learn@giancolianswers.com"
        >
          learn@giancolianswers.com
        </a>
      </div>
    </div>
  );
};

export default Contact;
