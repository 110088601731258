import { Link } from "gatsby";
import React from "react";
import { classNames } from "../../utils";

const faqs: {
  question: string;
  answer: string;
}[] = [
  {
    question: `What type of payment methods do you accept?`,
    answer: `Giancoli Answers accepts PayPal and credit or debit cards from Visa, Mastercard, American Express,
    Maestro, Discover, Diners Club, JCB, UnionPay, and Mada`,
  },
  {
    question: `What is your refund policy?`,
    answer: `If you're not satisfied, get your money back within <b>90 days</b>
    of purchase by emailing <a class="text-blue-700 hover:text-blue-800 hover:underline" href="mailto:learn@giancolianswers.com">learn@giancolianswers.com</a>.`,
  },
  {
    question: `Is this website and my credit card information secure?`,
    answer: `Definitely! We partner with Paddle and Stripe for payments 
    processing. Your payment information is sent directly from your 
    browser to Paddle or Stripe and never reaches the Giancoli Answers 
    website servers.`,
  },
];
const Faq = () => {
  return (
    <div className="mt-6">
      {faqs.map((item, index) => (
        <div key={index}>
          <h3
            className={classNames(
              index !== 0 && "mt-4",
              "font-bold text-neutral-700"
            )}
          >
            {item.question}
          </h3>
          <p
            className="text-neutral-600 mt-4"
            dangerouslySetInnerHTML={{ __html: item.answer }}
          />
        </div>
      ))}
      <Link
        className="block mt-4 text-blue-700 hover:text-blue-800 hover:underline font-bold"
        to="/faq"
      >
        More frequently asked questions
      </Link>
    </div>
  );
};

export default Faq;
