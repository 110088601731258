import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { classNames } from "../../utils";
import QuoteStart from "./quote-start.svg";
import QuoteEnd from "./quote-end.svg";

const reviews: {
  content: string;
  author: string;
}[] = [
  {
    content: `I wanted to thank you for taking the time and effort of working out the solutions!
     I know that was a tedious task, but those of us that needed that extra assistance 
     GREATLY appreciate it!! I would not have been able to have done as well as I did in 
     Physics II without the breakdown step by step clarification that was provided. 
     Thanks again for your effort!
     I would highly recommend the use of this site to anyone taking Physics.`,
    author: `Kristie, Winston-Salem State University, NC`,
  },
  {
    content: `Dear Mr. Dychko, Physics has never been my strong point so I was nervous about 
      taking Physics this semester. I am so glad that I found your website! 
      I am often confused during my classes because my teacher goes so
      quickly over the material and tends to be disorganized. 
      Doing the book problems along with watching your solution 
      videos has helped me understand the material a lot better. 
      You have even showed me a few tricks that I've been able to 
      successfully use during my exams. Thank you for making this site!`,
    author: `Student at University of California, Irvine`,
  },
  {
    content: `These videos are 40 times more valuable than lecture, 
    the text book, and so much cheeper and efficient than a private tutor.`,
    author: `Maggie, Northwestern University, Evanston, IL`,
  },
  {
    content: `Thank you so much... the videos were very helpful. 
    I was studying at McGill in a class of 650 students so it was very helpful 
    to be able to go over any questions I was unsure of. 
    And your explanations were great. 5 out of 5 star rating from me.`,
    author: `Kelly, McGill University, Montreal, Canada`,
  },
  {
    content: `Thank you so much for making all these videos, you helped me get an 
    A in both my college physics courses! With your help I ended up working just about 
    every problem in the Giancoli book. Not only did I get the good grades but these 
    exercises have proven invaluable to understanding and working more 
    complex problems in the advanced classes for my major in Mechanical Engineering.`,
    author: `Robert, Greenville Technical College, Greenville, SC`,
  },
];

const Reviews = () => {
  return (
    <div className="mt-10">
      {/* Avatars */}
      <div className="flex justify-center items-center space-x-3 sm:space-x-10">
        <StaticImage src="person1.png" alt="avatar 1" width={80} />

        <StaticImage src="person2.png" alt="avatar 2" width={80} />

        <StaticImage src="person3.png" alt="avatar 3" width={80} />

        <StaticImage src="person5.png" alt="avatar 5" width={80} />

        <div className="hidden sm:block">
          <StaticImage src="person4.png" alt="avatar 4" width={80} />
        </div>
      </div>
      {/* Content */}
      {reviews.map((review, index) => (
        <div key={index} className="">
          <div
            className={classNames(
              index !== 0 && "border-t border-neutral-200",
              "grow py-10"
            )}
          >
            <h3 className="font-medium text-sm text-neutral-900 relative pl-8">
              {review.author}
              <img
                className="absolute left-0 top-1 md:top-9"
                src={QuoteStart}
                alt="Quote start"
                width={25}
              />
            </h3>
            <div className="prose prose-sm mt-4 text-sm max-w-none text-neutral-600 relative md:px-8">
              {review.content}
              <img
                className="absolute right-0 bottom-0"
                width={25}
                src={QuoteEnd}
                alt="Quote end"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Reviews;
