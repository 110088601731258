import { Dialog, Transition } from "@headlessui/react";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { Fragment, useEffect, useState } from "react";
import { CmPlan } from "../../types";
import Price from "../checkout/Price";
import Info from "./info.svg";
import IconBestValue from "./icon-best-value.svg";

const Pricing = () => {
  const {
    allCmPlanCmPlan: { nodes: plans },
    allFile: { nodes: paymentMethods },
  }: {
    allCmPlanCmPlan: {
      nodes: CmPlan[];
    };
    allFile: {
      nodes: {
        name: string;
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      }[];
    };
  } = useStaticQuery(graphql`
    query {
      allCmPlanCmPlan {
        nodes {
          billing_type
          title
          price_usd
          product_id
          created
          environment
        }
      }
      allFile(
        filter: {
          name: { in: ["generic", "paypal"] }
          sourceInstanceName: { eq: "paymentIcons" }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(height: 24, layout: CONSTRAINED)
          }
          name
        }
      }
    }
  `);
  const [open, setOpen] = useState(false);

  const planMonthly = plans.find(
    (plan) =>
      plan.product_id === parseInt(process.env.GATSBY_PRODUCT_ID_MONTHLY) &&
      plan.environment === process.env.GATSBY_ENVIRONMENT
  );
  const planAnnual = plans.find(
    (plan) =>
      plan.product_id === parseInt(process.env.GATSBY_PRODUCT_ID_ANNUAL) &&
      plan.environment === process.env.GATSBY_ENVIRONMENT
  );

  useEffect(() => {
    if (typeof gtag === "function") {
      gtag("event", "view_item");
    }
  }, [])

  return (
    <div className="grid grid-cols-2 gap-x-4 md:gap-x-8 gap-y-3 relative z-30">
      <div className="font-bold xs:text-xl text-neutral-700">
        {planMonthly && (
          <Price
            priceUsd={planMonthly.price_usd}
            productId={planMonthly.product_id}
          />
        )}
        <span className="font-normal text-base text-neutral-600"> /month</span>
      </div>
      <div className="font-bold xs:text-xl text-neutral-700">
        {planAnnual && (
          <Price
            priceUsd={planAnnual.price_usd}
            productId={planAnnual.product_id}
          />
        )}
        <span className="font-normal text-base text-neutral-600"> /year</span>
        <img
          className="inline-block ml-1 xs:ml-2 lg:ml-4 w-[15px] xs:w-auto"
          src={IconBestValue}
          alt="Best value icon"
        />
      </div>
      <div>
        <Link
          className="bg-blue-700 text-sm md:text-base lg:text-lg text-white rounded-2xl
    focus:bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700
    active:bg-blue-800 px-2 py-3 w-full inline-block text-center"
          to="/sign-up"
          data-cy="signup-monthly"
        >
          START MONTHLY PLAN
        </Link>
        <div className="flex mt-4">
          <div className="w-8 lg:w-10">
            <GatsbyImage
              className="mr-3"
              image={
                paymentMethods.find((item) => item.name === "generic")
                  .childImageSharp.gatsbyImageData
              }
              alt="Generic card payment method"
            />
          </div>
          <div className="w-16 lg:w-24">
            <GatsbyImage
              className=""
              image={
                paymentMethods.find((item) => item.name === "paypal")
                  .childImageSharp.gatsbyImageData
              }
              alt="PayPal payment method"
            />
          </div>
        </div>
      </div>
      <div>
        <Link
          className="bg-blue-700 text-sm md:text-base lg:text-lg text-white rounded-2xl
    focus:bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700
    active:bg-blue-800 px-2 py-3 w-full inline-block text-center"
          to="/sign-up"
        >
          SAVE ON A YEAR
        </Link>
        <div className="flex xs:gap-2 items-center mt-3">
          <div className="w-24 xs:w-auto tracking-wide text-neutral-700 text-sm">
            Money back guarantee!
          </div>
          <div className="">
            <button className="block w-5" onClick={() => setOpen(true)}>
              <img
                className="w-full"
                src={Info}
                alt="More information about money back guarantee"
              />
            </button>
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} />
    </div>
  );
};

export default Pricing;

const Modal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-neutral-900"
                    >
                      Money back guarantee
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-neutral-500">
                        If you're not satisfied, get your money back within{" "}
                        <b>90 days</b> of purchase by emailing{" "}
                        <a
                          className="text-blue-700 hover:text-blue-800 hover:underline"
                          href="mailto:learn@giancolianswers.com"
                        >
                          learn@giancolianswers.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-lg border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Got it!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
